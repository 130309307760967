<template>
  <div id="businessCircle-ranking" v-loading="loading">
    <ul v-for="(item, index) of list" :key="item.shopName">
      <li class="ranking-img" v-if="index === 0 || index === 1 || index === 2">
        <el-image
          :src="require(`@/assets/images/ranking${index + 1}.png`)"
          fit="contain"
        ></el-image>
      </li>
      <li v-else>{{ index + 1 }}</li>
      <li :title="item.shopName" class="shopName">{{ item.shopName }}</li>
      <li class="progress">
        <el-progress
          :width="35"
          :stroke-width="2"
          type="circle"
          :percentage="item.issuedProportion"
        ></el-progress>
      </li>
      <li>{{ item.issuedCount }}/{{ item.issuedCount + 20 }}</li>
    </ul>
  </div>
</template>

<script>
import { formatRatio } from "@/utils/util";

export default {
  data() {
    return {
      //停车场排名
      loading: false,
      list: [],
    };
  },
  created() {
    this.getRanking();
  },
  methods: {
    async getRanking(params = { type: "本月" }) {
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/merchant/coupon/rank", {
          size: 10,
          ...params,
        });
        if (res.code === 0) {
          console.log(res.data);
          for (let item of res.data) {
            item.issuedProportion = formatRatio(item.issuedProportion);
          }
          this.$set(this, "list", res.data);
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch {
        this.$message.error("排名获取失败");
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#businessCircle-ranking {
  width: 100%;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  ul {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }

    li {
      text-align: center;
      font-size: 1rem;
      white-space: nowrap;
      &.shopName {
        text-align: left;
      }
      &:first-child {
        flex: initial;
        display: inline-block;
        width: 1.45rem;
        height: 1.45rem;
        line-height: 1.45rem;
        border-radius: 50%;
        background-color: #02425e;
        margin-right: 0.31rem;
      }

      &.ranking-img {
        background-color: initial;
      }

      .el-image {
        width: 1.45rem;
        height: 1.45rem;
      }

      &.progress {
        margin-left: auto;
        .el-progress {
          .el-progress__text {
            color: white;
          }
        }
      }
      &:last-child {
        width: 100px;
        text-align: right;
      }
    }
  }
}
</style>
